'use client';

import {
  GoogleMap,
  InfoWindow,
  MarkerF,
  useLoadScript,
} from '@react-google-maps/api';
import { useContext, useEffect, useRef } from 'react';
import { AddressMapContext, IMapCordinate } from './context';
import React from 'react';
import { silverMapStyle } from './utils';
import { getFromLocalStorage } from 'utils/local-storage';
import { setCloseMapInfo } from 'redux/features/shipping-address-modal';
//import pin from '/assets/images/pin.svg';
import { useAppDispatch } from 'redux/hooks';
import { setSelectedStoreFromMap } from 'redux/features/checkout';

import type { StoreStock } from 'types/store';
import { gtmSetApiCall } from 'lib/gtm';

const libs = ['places'];

export default function AddressMapView({
  markersComponent,
  centerPosition,
  isStoreAvailability,
}: {
  markersComponent?: JSX.Element | JSX.Element[];
  centerPosition?: IMapCordinate;
  isStoreAvailability?: boolean;
}) {
  const {
    setMap,
    markers,
    centerMarker,
    containerStyle,
    cordinate,
    updateWithLatLng,
    setActiveMarker,
    setSelectedStore,
    selectedStore,
    isUnknownLocation,
    setIsUnknownLocation,
    t,
  } = useContext(AddressMapContext);

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!,
    libraries: libs as never,
    language: getFromLocalStorage({ key: 'i18nextLng' }) ?? 'en',
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const appDispatch = useAppDispatch();
  const [unavailableStore, setUnavailableStore] = React.useState({
    position: { lat: 0, lng: 0 },
  });
  const [nearestStore, setNearestStore] = React.useState({
    position: { lat: 0, lng: 0 },
  });

  useEffect(() => {
    if (!markers || !markers.length) return;
    let shortestDistance = -1;
    let shortestStore = markers[0]?.store as StoreStock;
    markers.map((marker) => {
      if (marker?.store?.in_stock && marker?.store?.distance) {
        const newDist = parseFloat(marker.store.distance);
        if (shortestDistance === -1 || newDist < shortestDistance) {
          shortestDistance = newDist;
          shortestStore = marker.store as StoreStock;
          setNearestStore({ position: marker.position });
        }
      }
    });

    if (shortestDistance === -1) {
      setNearestStore({ position: { lat: 0, lng: 0 } });
    } else if (shortestStore !== null) {
      appDispatch(setSelectedStoreFromMap(false));
      setSelectedStore!(shortestStore);
    }
  }, [markers, appDispatch, setSelectedStore]);

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      //   const bounds = new window.google.maps.LatLngBounds(center);
      //  map.fitBounds(bounds);
      gtmSetApiCall(map);
      mapRef.current = map;
      setMap?.(map);
    },
    [setMap]
  );

  const onUnmount = React.useCallback(
    function callback() {
      setMap?.(null);
    },
    [setMap]
  );

  const markerDragStartEv = React.useCallback(
    function callback() {
      setIsUnknownLocation?.(false);
    },
    [setIsUnknownLocation]
  );

  const markerDragEv = React.useCallback(
    /* eslint-disable */
    function callback(e: any) {
      updateWithLatLng?.(
        e.latLng.lat(),
        e.latLng.lng(),
        mapRef.current ?? undefined
      );
    },
    /* eslint-enable */
    [updateWithLatLng]
  );
  const dispatch = useAppDispatch();

  return (
    <div id='map-canva' className='absolute left-0 right-0 h-full w-full'>
      <style>
        {`
          .gm-ui-hover-effect {
            display: none !important;
          }
          .gm-style-iw, .gm-style-iw  * {
            background-color: #fff1d7 !important;
            overflow: hidden
          }
          .gm-style-iw-d{
            overflow:hidden !important;
          }
          .gm-style .gm-style-iw-tc::after{
            background-color: #fff1d7 !important;
          }
        `}
      </style>
      {isStoreAvailability && (
        <style>
          {`
          .gm-ui-hover-effect {
            display: none !important;
          }
          .gm-style-iw, .gm-style-iw  * {
            background-color: white !important;
            overflow: hidden
          }
          .gm-style-iw-d{
            overflow:hidden !important;
          }
          .gm-style .gm-style-iw-tc::after{
            background-color: #D4232D !important;
          }
          .gm-style .gm-style-iw-tc::before {
            background: #fff;
            -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            content: "";
            height: 11px;
            left: 0;
            position: absolute;
            top: -1px;
            width: 25px;
            z-index: 1;
          }
          .gm-style-iw-c {
            border: 1px solid #D4232D;
            padding: 0 !important;
          }
          .gm-style-iw[aria-label="${t?.(
            'shipping_method_switcher.nearest'
          )}"] {
            padding-top: 0 !important;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 9px;
            border: 1px solid #2C6BA4;
            border-radius: 16px;
          }
          .gm-style-iw[aria-label="${t?.(
            'shipping_method_switcher.nearest'
          )}"] + .gm-style-iw-tc::after {
            background-color: #2C6BA4 !important;
          }
          .gm-style-iw-chr {
            display:none;
          }

          .gm-bundled-control-on-bottom {
            bottom: 138px !important;  
          }
        `}
        </style>
      )}
      {isLoaded ? (
        <GoogleMap
          onLoad={onLoad}
          onUnmount={onUnmount}
          center={centerPosition ? centerPosition : cordinate}
          zoom={+(process.env.NEXT_PUBLIC_GOOGLE_MAP_DEFAULT_ZOOM ?? 14)}
          options={{
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: silverMapStyle,
            disableDefaultUI: true,
          }}
          mapContainerStyle={containerStyle}
        >
          {centerMarker && (
            <MarkerF
              draggable={false}
              position={centerMarker.position}
              icon='/assets/images/store-pickup-location.svg'
              clickable={false}
              opacity={1}
            />
          )}
          {markersComponent
            ? markersComponent
            : markers.map(({ position, draggable, markerIcon, store }, i) => (
                <MarkerF
                  key={i}
                  draggable={draggable}
                  onDragStart={() => {
                    markerDragStartEv();
                    dispatch(setCloseMapInfo(true));
                  }}
                  onDragEnd={markerDragEv}
                  position={position}
                  icon={
                    markerIcon ?? {
                      url: '/assets/images/pin.svg',
                      scaledSize: new window.google.maps.Size(50, 50),
                    }
                  }
                  clickable={setSelectedStore ? true : false}
                  onClick={(e) => {
                    if (!store) return;
                    setUnavailableStore({ position: { lat: 0, lng: 0 } });
                    if (
                      !store.in_stock &&
                      (('partially_available' in store &&
                        store.partially_available === false) ||
                        'partially_available' in store === false)
                    ) {
                      setUnavailableStore({ position });
                      return;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    appDispatch(setSelectedStoreFromMap(true));
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    setActiveMarker!(e.latLng);

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    selectedStore?.shop_id === store?.shop_id
                      ? setSelectedStore!(undefined)
                      : setSelectedStore!({
                          partially_available:
                            'partially_available' in store &&
                            store.partially_available,
                          ...store,
                        });
                  }}
                  opacity={
                    setSelectedStore
                      ? selectedStore &&
                        selectedStore?.shop_id !== store?.shop_id
                        ? 0.2
                        : 1
                      : 1
                  }
                />
              ))}

          {isUnknownLocation && markers?.[0]?.position && (
            <div className='info-wrap'>
              <InfoWindow
                position={cordinate}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -42),
                }}
              >
                <div className='h-[full] w-full bg-[#fff1d7] px-4 pb-4 pt-2'>
                  <h3>{t?.('shipping_method_switcher.area_not_covered')}</h3>
                </div>
              </InfoWindow>
            </div>
          )}

          {unavailableStore?.position?.lat &&
            unavailableStore?.position?.lng && (
              <InfoWindow
                position={unavailableStore.position}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -42),
                }}
              >
                <h3 className='px-12 py-3 font-semibold text-error ltr:font-montserrat rtl:font-tajawal'>
                  {t?.('shipping_method_switcher.items_not_available')}
                </h3>
              </InfoWindow>
            )}

          {nearestStore?.position?.lat && nearestStore?.position?.lng && (
            <InfoWindow
              position={nearestStore.position}
              options={{
                pixelOffset: new window.google.maps.Size(0, -42),
                ariaLabel: t?.('shipping_method_switcher.nearest'),
              }}
            >
              <h3 className='px-3 py-1 font-semibold text-blue ltr:font-montserrat rtl:font-tajawal'>
                {t?.('shipping_method_switcher.nearest')}
              </h3>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <p>loading...</p>
      )}
    </div>
  );
}
