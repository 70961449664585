import React from 'react';

export default function Target() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.7924 11.9998C16.7924 14.6507 14.6434 16.7997 11.9925 16.7997C9.34166 16.7997 7.19284 14.6507 7.19284 11.9998C7.19284 9.34891 9.34166 7.20009 11.9925 7.20009C14.6434 7.20009 16.7924 9.34891 16.7924 11.9998Z'
        fill='#2C6BA4'
      />
      <path
        d='M23.28 11.2798H21.6752C21.3144 6.48711 17.5054 2.67746 12.7128 2.31624V0.720009C12.7128 0.322323 12.3902 0 11.9927 0C11.5951 0 11.2727 0.322323 11.2727 0.720009V2.31624C6.47986 2.67746 2.67065 6.48711 2.30987 11.2798H0.720009C0.322323 11.2798 0 11.6023 0 11.9998C0 12.3975 0.322323 12.7198 0.720009 12.7198H2.30987C2.67065 17.5124 6.47986 21.3221 11.2725 21.6833V23.28C11.2725 23.6777 11.5948 24 11.9925 24C12.39 24 12.7123 23.6777 12.7123 23.28V21.6833C17.5052 21.3223 21.3144 17.5127 21.6752 12.7198H23.28C23.6777 12.7198 24 12.3975 24 11.9998C24 11.6023 23.6777 11.2798 23.28 11.2798ZM11.9925 20.2793C7.42002 20.2791 3.71342 16.5721 3.71342 11.9996C3.71364 7.42683 7.42068 3.72023 11.9932 3.72023C16.5659 3.72023 20.2727 7.42727 20.2727 11.9998C20.2672 16.5705 16.5633 20.2743 11.9925 20.2793Z'
        fill='#2C6BA4'
      />
    </svg>
  );
}
