import clsx from 'clsx';
import { LocationIcon } from 'components/icon';
import { Field } from 'formik';
import Image from 'next/image';
import { useMediaQuery } from 'hooks/use-media-query';

export default function AddressInput({
  cmsIcon,
  isEdit,
}: {
  cmsIcon?: string;
  isEdit?: boolean;
}) {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <>
      {isEdit && isMobile ? (
        <div className='flex h-10 w-full gap-3'>
          {cmsIcon ? (
            <Image src={cmsIcon} alt='icon' width={40} height={40} />
          ) : (
            <LocationIcon className={clsx('h-10 w-10 stroke-blue')} />
          )}
          <Field
            id='address'
            name='address'
            className='h-10 w-full resize-none overflow-hidden text-clip break-words'
            as='textarea'
          />
        </div>
      ) : (
        <div className='relative h-[50px]'>
          {cmsIcon ? (
            <Image
              src={cmsIcon}
              alt='icon'
              width={16}
              height={20}
              className={clsx(
                'h-5 w-auto flex-initial',
                'absolute top-[50%] -translate-y-1/2',
                'translate-x-[-50%] text-light-gray ltr:left-6 rtl:-right-2',
                'stroke-blue'
              )}
            />
          ) : (
            <LocationIcon
              className={clsx(
                'h-5 w-auto',
                'absolute top-[50%] -translate-y-1/2',
                'translate-x-[-50%] text-light-gray ltr:left-6 rtl:-right-2',
                'stroke-blue'
              )}
            />
          )}
          <Field
            id='address'
            name='address'
            className={clsx(
              'h-full w-full rounded-lg border border-light-gray',
              'text-custom-base placeholder:text-gray ltr:pl-[44px] rtl:pr-[44px]',
              'focus:border-2 focus:border-blue focus:ring-0',
              'js-search-input px-2'
            )}
          />
        </div>
      )}
    </>
  );
}
