import { Switch } from 'components/switch';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'app/i18n/client';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';

interface IToggleProps {
  fieldName: string;
  editAddressCmsContent?: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}

export default function ToggleInput({
  fieldName,
  editAddressCmsContent,
  language,
  region,
}: IToggleProps) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
/* eslint-disable */
  const handleChange = async (formik: any, name: string, value: boolean) => {
    await formik.setFieldValue(name, value);
    formik.setFieldTouched(name, true);
  };
  return (
    <Field name={fieldName} id={fieldName} type='number'>
      {({ field, form }: any) => (
        <div className='mt-5 flex items-center space-x-2 rtl:space-x-reverse'>
          <Switch
            checked={field.value}
            onChange={() => handleChange(form, fieldName, !field.value)}
            name='default-address'
          />
          
          <span className='text-custom-base text-gray-dark'>
            {editAddressCmsContent ?? t('shipping_method_switcher.address_info.default_address')}
          </span>
        </div>
      )}
    </Field>
  );
}
