import { createSlice } from '@reduxjs/toolkit';
import { CountryType, DistrictType } from 'types/country';
import { INearByStore } from 'types/shipping-method-switcher';
import { StoreStock } from 'types/store';
type StoreAvailability = {
  countries: CountryType[] | undefined;
  selectedDistrict: DistrictType | undefined;
  selectedDistance: string | undefined;
  selectedCoords: { lat: number; lng: number } | undefined;
  stores: INearByStore[] | null;
  checkoutStores: StoreStock[] | null;
  currentLocation: { longitude: number; latitude: number } | undefined;
};

const initialState: StoreAvailability = {
  countries: undefined,
  selectedDistrict: undefined,
  selectedCoords: undefined,
  stores: null,
  checkoutStores: null,
  selectedDistance: undefined,
  currentLocation: undefined,
};

export const storeAvailabilitySlice = createSlice({
  name: 'storeAvailability',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setSelectedDistrict: (state, action) => {
      state.selectedDistrict = action.payload;
    },
    setSelectedDistance: (state, action) => {
      state.selectedDistance = action.payload;
    },
    setSelectedCoords: (state, action) => {
      state.selectedCoords = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    setCheckoutStores: (state, action) => {
      state.checkoutStores = action.payload;
    },
    setCurrentLocaiton: (state, action) => {
      state.currentLocation = action.payload;
    },
  },
});

export const {
  setCountries,
  setSelectedDistrict,
  setSelectedDistance,
  setSelectedCoords,
  setStores,
  setCheckoutStores,
  setCurrentLocaiton,
} = storeAvailabilitySlice.actions;

export default storeAvailabilitySlice.reducer;
