import {
  IAddressData,
  SearchAddressType,
} from 'types/shipping-method-switcher';

export function toAddressFormat(data: SearchAddressType) {
  const address: IAddressData = {
    type: data.type,
    firstname: data.firstName ?? '',
    lastname: data.lastName ?? '',
    street: [data.location.streetName],
    city: data.location.city,
    region: {
      region: data.location.regionName,
      region_code: data.location.regionCode,
    },
    default_billing: data.isDefault,
    default_shipping: data.isDefault,
    postcode: data.location.postalCode,
    latitude: data.lat,
    longitude: data.lng,
    country_code: data.location.countryCode,
    telephone: extractCustomerInfoFromLocalStorage()?.phone ?? '',
    vat_id: '',
    additional: {
      place_id: data.place_id,
      formatted_address: data.formatted_address,
      otherOptions: data.otherDescription,
    },
  };

  return address;
}

export const extractCustomerInfoFromLocalStorage = () => {
  const raw = localStorage.getItem('miniProfile');
  if (!raw) return null; // just placeholder for non-logged in;
  const {
    data: { customerId, phone, sub, firstname, lastname },
  } = JSON.parse(raw);
  return { customerId, phone, sub, firstname, lastname };
};
