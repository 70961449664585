import { useContext } from 'react';
import { AddressMapContext } from './context';
import AddressInformation from '../address-information';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setMapOptions } from 'redux/features/address.reducer';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function AddressOptionsView({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const isMapOptionsOpen = useAppSelector(
    (state) => state.addressReducer.isMapOptionsOpen
  );
  const { selectedAddress, submitAddress, isUnknownLocation } =
    useContext(AddressMapContext);
  const appDispatch = useAppDispatch();

  return (
    isMapOptionsOpen &&
    selectedAddress && (
      <AddressInformation
        isUnknownLocation={isUnknownLocation}
        address={selectedAddress}
        setAddressInformationShow={(v: boolean) =>
          appDispatch(setMapOptions(v))
        }
        submitAddress={submitAddress}
        language={language}
        region={region}
      />
    )
  );
}
