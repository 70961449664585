'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'app/i18n/client';
import { AddressMapContext, IMapCordinate, IMarkerData } from './context';
import AddressMapView from './map-view';
import AddressOptionsView from './address-option-view';
import AddressSearchInput from './address-search-input-view';
import AddressLocationPicker from './user-location-pick-view';
import {
  IAddressData,
  SearchAddressType,
} from 'types/shipping-method-switcher';
import { DEFAULT_GEO, allowedCountries, extractAddressDetails } from './utils';
import { toAddressFormat } from './utils/address.util';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setIsUpdatingOrSaving,
  setMapModal,
  setMapOptions,
  setzoneId,
} from 'redux/features/address.reducer';
import {
  addAddress,
  setGuestProfile,
  updateAddress as updateAd,
} from 'redux/features/customer';
import {
  getNearByZoneIdByCordinate,
  saveAddressData,
  updateAddress,
} from 'lib/googlemap';
import Notification from 'components/notifications';
import { toast } from 'react-toastify';
import StoreLocationPicker from './store-picker/user-location-pick-view';
import { setSelectedStore as setSelectedStoreRedux } from 'redux/features/checkout';
import { StoreStock } from 'types/store';
import clsx from 'clsx';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { gtmSetAddShippingAddress, gtmSetLocationDetection } from 'lib/gtm';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function AddressPickerWrapper({
  isStoreAvailability,
  children,
  language,
  region,
}: {
  isStoreAvailability?: boolean;
  children: JSX.Element | JSX.Element[];
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const isMapOptionsOpen = useAppSelector(
    (state) => state.addressReducer.isMapOptionsOpen
  );
  const appDispatch = useAppDispatch();
  const { miniProfile, editId, addressList, guest } = useAppSelector(
    (state) => state.customerReducer
  );
  const { selectedStore: selectedStoreFromRedux, selectedStoreFromMap } =
    useAppSelector((state) => state.checkoutReducer);

  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = useState<IMarkerData[]>([
    ...(!isStoreAvailability
      ? [{ position: DEFAULT_GEO, draggable: true }]
      : []),
  ]);
  const [centerMarker, setCenterMarker] = useState<IMarkerData | undefined>(
    undefined
  );
  const [selectedStore, setSelectedStore] = useState<StoreStock | undefined>(
    undefined
  );

  const windowIsMobileSize = useMediaQuery(mobileSize);
  useEffect(() => {
    if (windowIsMobileSize && isStoreAvailability) {
      document
        ?.querySelector('main')
        ?.classList.toggle('overflow-hidden', !selectedStore);

      document
        ?.querySelector('main')
        ?.classList.toggle('max-h-[100vh]', !selectedStore);
    }
  }, [windowIsMobileSize, isStoreAvailability, selectedStore]);

  useEffect(() => {
    if (isStoreAvailability) {
      appDispatch(setSelectedStoreRedux(selectedStore));
    }
  }, [appDispatch, isStoreAvailability, selectedStore]);

  useEffect(() => {
    if (!selectedStoreFromMap && selectedStoreFromRedux) {
      setSelectedStore(selectedStoreFromRedux);
    }
  }, [selectedStoreFromRedux, selectedStoreFromMap]);

  const [activeMarker, setActiveMarker] = useState<google.maps.LatLng | null>(
    null
  );
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [isUnknownLocation, setIsUnknownLocation] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<IAddressData | null>(
    null
  );
  // eslint-disable-next-line no-unused-vars
  const containerStyle = {
    width: '100%',
    height: '100%',
  };
  const [cordinate, setCordinate] = useState<IMapCordinate>(DEFAULT_GEO);

  const updateMapEditData = useCallback(() => {
    let address: IAddressData | undefined = undefined;
    if (miniProfile) {
      const index = addressList.findIndex((add) => add.id == editId);
      if (index != -1 && addressList?.[index]) {
        address = addressList[index]!;
      }
    } else if (guest?.addressList && guest.addressList.length > 0) {
      address = guest?.addressList[0];
    }

    if (address) {
      if (address.longitude && address.latitude) {
        setCordinate({ lat: address.latitude, lng: address.longitude });
        const mp = map;
        mp?.setZoom(+(process.env.NEXT_PUBLIC_GOOGLE_MAP_FOCUS_ZOOM ?? 18));
        const activeMarkerIndex = 0;
        setMarkers?.((markers) => {
          const mks = [...markers];
          const marker: IMarkerData = {
            ...mks[activeMarkerIndex]!,
            position: {
              lat: address?.latitude ?? 0,
              lng: address?.longitude ?? 0,
            },
          };
          mks[activeMarkerIndex] = marker;
          return mks;
        });
      }
      appDispatch(setMapOptions(true));
      setSelectedAddress?.(address);
    }
  }, [addressList, guest, editId, miniProfile, map, appDispatch]);

  useEffect(() => {
    if (editId) {
      updateMapEditData();
    }
  }, [editId, updateMapEditData]);

  const updateWithLatLng = (
    lat: number,
    lng: number,
    mapRef?: google.maps.Map
  ) => {
    const place = new google.maps.LatLng(lat, lng);
    setIsUnknownLocation(false);
    setCordinate({ lat, lng });
    const request = {
      location: place,
      radius: +(process.env.NEXT_PUBLIC_NEAR_BY_PLACES_RADIUS ?? 10),
      query: ' ',
    };
    const mp = mapRef ?? map;
    const service = new google.maps.places.PlacesService(mp!);
    service.nearbySearch(request, (results, status) => {
      if (
        results &&
        results.length > 0 &&
        status == google.maps.places.PlacesServiceStatus.OK
      ) {
        const closest = results?.[1] ?? results[0];
        service.getDetails({ placeId: closest?.place_id ?? '' }, (place) => {
          appDispatch(setMapOptions(true));
          const addressDetail = extractAddressDetails(
            place?.address_components ?? []
          );
          if (
            !addressDetail.countryCode ||
            !allowedCountries.includes(addressDetail.countryCode)
          ) {
            setIsUnknownLocation(true);
          }
          const data: SearchAddressType = {
            place_id: closest?.place_id ?? '',
            lat: place?.geometry?.location?.lat() ?? 0,
            lng: place?.geometry?.location?.lng() ?? 0,
            type: 'Home',
            isDefault: false,
            url: place?.url,
            formatted_address: place?.formatted_address ?? '',
            location: addressDetail,
            firstName: miniProfile?.firstname,
            lastName: miniProfile?.lastname,
            telephone: miniProfile?.mobile_number,
          };
          const formattedAddress = toAddressFormat(data);
          mp?.setZoom(+(process.env.NEXT_PUBLIC_GOOGLE_MAP_FOCUS_ZOOM ?? 18));
          gtmSetLocationDetection(formattedAddress);
          setSelectedAddress?.(formattedAddress);
        });
      }
    });
  };

  const saveAddresstoServer = async (data: IAddressData) => {
    data.telephone = miniProfile?.mobile_number ?? '';
    const result = await Promise.all([
      saveAddressData<IAddressData>(
        {
          ...data,
          default_shipping:
            addressList.length == 0 ? true : data.default_shipping,
          default_billing:
            addressList.length == 0 ? true : data.default_billing,
          street: data.additional?.formatted_address
            ? [data.additional?.formatted_address]
            : data.street,
          additional: undefined,
        },
        language,
        region
      ),
      // getNearByStores(data.additional?.lng ?? 0, data.additional?.lat ?? 0),
      getNearByZone(data.longitude ?? 0, data.latitude ?? 0),
    ]);
    // We don't have default shipping field in response so we have to add this field manually if it comes from form data
    if (result?.[0]) {
      if (data?.default_shipping) {
        result[0].default_shipping = true;
      }
      appDispatch(addAddress(result[0]));
    }
  };

  const showToast = (theme: 'success' | 'error', translateString: string) => {
    toast(
      <Notification
        componentProps={{
          closeIcon: true,
          text: ts(translateString),
        }}
      />,
      {
        autoClose: 800,
        hideProgressBar: true,
        theme: theme,
        position: 'top-center',
      }
    );
  };

  const updateAddressOnTheServer = async (data: IAddressData) => {
    data.telephone = miniProfile?.mobile_number ?? '';
    const result = await updateAddress<IAddressData>(
      {
        ...data,
        default_shipping:
          addressList.length == 1 ? true : data.default_shipping,
        default_billing: addressList.length == 1 ? true : data.default_billing,
        street: data.additional?.formatted_address
          ? [data.additional?.formatted_address]
          : data.street,
        additional: undefined,
      },
      language,
      region
    );
    if (result) {
      if (data.default_shipping) {
        result.default_shipping = true;
      }
      appDispatch(updateAd(result));
    }
  };

  const submitAddress = async (data: IAddressData) => {
    try {
      appDispatch(setIsUpdatingOrSaving(true));
      if (miniProfile) {
        if (editId) {
          await updateAddressOnTheServer({ ...data, id: +editId });
        } else {
          await saveAddresstoServer(data);
        }
      } else {
        appDispatch(
          setGuestProfile({
            ...data,
            default_shipping: true,
            default_billing: true,
          })
        );
        await getNearByZone(data.longitude ?? 0, data.latitude ?? 0);
      }
      gtmSetAddShippingAddress(data, miniProfile?.magento_customer_id || '');
      showToast('success', 'shipping_method_switcher.toast.address_saved');
      appDispatch(setMapOptions(false));
      appDispatch(setMapModal(false));
    } catch (ex) {
      showToast(
        'error',
        'shipping_method_switcher.toast.failed_to_save_address'
      );
    } finally {
      appDispatch(setIsUpdatingOrSaving(false));
    }
  };

  const getNearByZone = async (lng: number, lat: number) => {
    const result: any = await getNearByZoneIdByCordinate(
      lng,
      lat,
      language,
      region
    ); // Please define a type If you read here
    appDispatch(setzoneId(result ?? ''));
    return result;
  };

  return (
    <AddressMapContext.Provider
      value={{
        setMarkers,
        markers,
        centerMarker,
        setCenterMarker,
        map,
        setMap,
        showAddressOptions,
        setCordinate,
        setShowAddressOptions,
        containerStyle,
        selectedAddress,
        setSelectedAddress,
        cordinate, // eslint-disable-next-line
        submitAddress, // eslint-disable-line @typescript-eslint/no-misused-promises
        updateWithLatLng,
        isUnknownLocation,
        setIsUnknownLocation,
        t,
        ts,
        ...(isStoreAvailability
          ? {
              selectedStore,
              setSelectedStore,
              activeMarker,
              setActiveMarker,
            }
          : {}),
      }}
    >
      <div
        className={clsx(
          'relative flex w-full flex-col justify-between px-8 lg:rounded-bl-lg lg:rounded-br-lg',
          !isStoreAvailability
            ? isMapOptionsOpen
              ? 'h-calc-60 lg:h-[700px]'
              : 'h-calc-60 lg:h-[450px]'
            : '',
          isStoreAvailability && selectedStore && 'h-[100vw] lg:h-[450px]',
          isStoreAvailability && !selectedStore && 'h-[100vh] lg:h-[450px]'
        )}
      >
        {children}
      </div>
    </AddressMapContext.Provider>
  );
}

AddressPickerWrapper.MapView = AddressMapView;
AddressPickerWrapper.Search = AddressSearchInput;
AddressPickerWrapper.Config = AddressOptionsView;
AddressPickerWrapper.PickMyLocation = AddressLocationPicker;
AddressPickerWrapper.StorePickMyLocation = StoreLocationPicker;
