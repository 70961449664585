import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'app/i18n/client';
import clsx from 'clsx';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';

export interface WorkOption {
  label: string;
  value: string;
}

const defaultOptions: WorkOption[] = [
  {
    label: 'shipping_method_switcher.address_info.home',
    value: 'Home',
  },
  {
    label: 'shipping_method_switcher.address_info.work',
    value: 'Work',
  },
  {
    label: 'shipping_method_switcher.address_info.other',
    value: 'Others',
  },
];

interface IWorkProps {
  fieldName: string;
  options?: WorkOption[];
  language: LanguageCodesType;
  region: RegionCodesType;
}

export default function WorkInput({
  fieldName,
  options = defaultOptions,
  language,
  region,
}: IWorkProps) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );

  const handleChange = async (formik: any, name: string, value: string) => {
    await formik.setFieldValue(name, value);
    formik.setFieldTouched(name, true);
  };

  return (
    <>
      <span className='text-custom-base text-gray-dark'>
        {t('shipping_method_switcher.address_info.address_type')}
      </span>
      <Field name={fieldName} id={fieldName} type='number'>
        {({ field, form }: any) => {
          const isOthers = !defaultOptions
            .map((d) => d.value)
            .includes(field.value);
          const upVal = isOthers ? 'Others' : field.value;
          return (
            <div className='mt-4 flex items-center space-x-2 rtl:space-x-reverse'>
              {options.map(({ label, value }, index) => (
                <button
                  type='button'
                  key={`item-${index}`}
                  onClick={() => {
                    handleChange(form, fieldName, value);
                  }}
                  className={clsx(
                    'rounded-lg border bg-white font-semibold',
                    'px-3 py-2 text-custom-xs hover:border-blue hover:text-blue',
                    value === upVal
                      ? 'border-blue text-blue'
                      : 'border-gray text-gray'
                  )}
                >
                  {t(label)}
                </button>
              ))}
            </div>
          );
        }}
      </Field>
    </>
  );
}
