import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'app/i18n/client';
import Input from 'components/input/input';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

interface IOthersProps {
  fieldName: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}

export default function OthersInput({
  fieldName,
  language,
  region,
}: IOthersProps) {
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
  const handleChange = async (formik: any, name: string, value: boolean) => {
    await formik.setFieldValue(name, value);
    formik.setFieldTouched(name, true);
  };

  return (
    <Field name={fieldName} id={fieldName} type='text'>
      {({ field, form }: any) => (
        <div className='mt-5'>
          <Input
            type='text'
            className='px-4'
            value={field.value}
            placeholder={ts(
              'shipping_method_switcher.address_info.other_placeholder'
            )}
            label={ts('shipping_method_switcher.address_info.other')}
            variant='primary'
            onChange={(e: any) => handleChange(form, fieldName, e.target.value)}
            language={language}
            region={region}
          />
        </div>
      )}
    </Field>
  );
}
