// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  modalOpen: boolean;
  setCloseMapInfo: boolean;
};
const initialState: TodoState = {
  modalOpen: false,
  setCloseMapInfo: false,
};
export const ShippingAddressModal = createSlice({
  name: 'shippingAddressModal',
  initialState,
  reducers: {
    setShippingAddressModal: (state, action) => {
      state.modalOpen = action.payload;
    },
    setCloseMapInfo: (state, action) => {
      state.setCloseMapInfo = action.payload;
    },
  },
});
export const { setShippingAddressModal } = ShippingAddressModal.actions;
export const { setCloseMapInfo } = ShippingAddressModal.actions;
export default ShippingAddressModal.reducer;
