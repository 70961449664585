'use client';

import { useContext } from 'react';
import { AddressMapContext } from '../context';
import { Button } from 'components/buttons/button';
import { LocationDetectIcon } from 'components/icon';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setLocationServiceDenied } from 'redux/features/checkout';
import { setCheckoutStores } from 'redux/features/store-availability';
import { cartStoreAvailability } from '../utils/store-pickup';
import { useTranslation } from 'app/i18n/client';
import { twMerge } from 'tailwind-merge';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import clsx from 'clsx';

import type { CartType } from 'types/my-cart';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function StoreLocationPicker({
  cart,
  language,
  region,
}: {
  cart?: CartType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
  const { setCordinate, setMarkers, setCenterMarker, selectedStore } =
    useContext(AddressMapContext);

  const isMapOptionsOpen = useAppSelector(
    (state) => state.addressReducer.isMapOptionsOpen
  );

  const windowIsMobileSize = useMediaQuery(mobileSize);

  const pickLocation = () => {
    if (!cart?.lineItems?.length) return;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenterMarker?.({
            position: {
              lat: position.coords.latitude ?? 0,
              lng: position.coords.longitude ?? 0,
            },
            draggable: false,
          });

          cartStoreAvailability({
            lat: position.coords.latitude ?? 0,
            lng: position.coords.longitude ?? 0,
            cart: cart,
            setCordinate,
            setMarkers,
            language,
            region,
          }).then((data) => {
            if (data && data.success && data.stores) {
              appDispatch(setCheckoutStores(data.stores));
            }
          });
        },
        (err) => {
          if (err?.code === 1) {
            appDispatch(setLocationServiceDenied(true));
          }
        }
      );
    }
  };

  if (isMapOptionsOpen) return null;

  if (windowIsMobileSize) {
    return (
      <>
        <Button
          onClick={pickLocation}
          className={clsx(
            'mb-4 ml-auto h-auto w-fit rounded-full bg-white p-3 drop-shadow-xl',
            !selectedStore && 'mb-64'
          )}
          wrapperClassName='flex items-center justify-between max-h-full'
        >
          <LocationDetectIcon className='has-color fill-current text-blue' />
        </Button>
        {!selectedStore && (
          <div
            className={twMerge(
              'fixed bottom-0 left-0 right-0 z-[52] w-full bg-white px-4 py-3 lg:hidden'
            )}
          >
            <div className='flex flex-col space-y-4 pt-3'>
              <span className='text-xl'>{t('location_picker.title')}</span>
              <span className='text-custom-sm text-gray-dark'>
                {t('location_picker.subtitle')}
              </span>
            </div>
            <Button
              className='mt-6 h-auto px-4 py-3'
              wrapperClassName='flex items-center justify-between h-auto max-h-full'
              onClick={pickLocation}
            >
              <LocationDetectIcon />
              <span className='ml-2 text-custom-base font-semibold'>
                {t('location_picker.cta_stores')}
              </span>
              {/* Don't remove this empty div */}
              <div></div>
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <Button
      onClick={pickLocation}
      className='mb-[64px] ml-auto h-auto w-fit px-3 py-2 lg:mt-[30px]'
      wrapperClassName='flex items-center justify-between h-auto max-h-full'
    >
      <LocationDetectIcon />
      <span className='ml-2 text-custom-sm'>
        {t('location_picker.cta_stores')}
      </span>
      {/* Don't remove this empty div */}
      <div></div>
    </Button>
  );
}
