import React, { useCallback, useEffect } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import {
  AddressInformationType,
  IAddressData,
} from 'types/shipping-method-switcher';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useTranslation } from 'app/i18n/client';
import AddressInput from './form-controls/adress.input';
import WorkInput from './form-controls/work.input';
import ToggleInput from './form-controls/toggle.input';
import { Button } from 'components/buttons/button';
import OthersInput from './form-controls/others.input';
import LoadingIndicator from './maps/map-loader';
import { useAppSelector } from 'redux/hooks';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { usePathname } from 'next/navigation';

interface IFormData {
  type: string;
  address: string;
  isDefault: boolean;
  otherOptions: string;
}

export default function AddressInformation({
  address,
  topPosition,
  submitAddress,
  isUnknownLocation,
  language,
  region,
}: AddressInformationType) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );

  const FormSchema = Yup.object().shape({
    type: Yup.string().required(),
    address: Yup.string().required(),
    isDefault: Yup.boolean().required(),
    otherOptions: Yup.string(),
  });

  function handleForm(data: IFormData) {
    const updatedAddress: IAddressData = {
      ...address,
      type:
        data.type === 'Others' && data.otherOptions !== ''
          ? data.otherOptions
          : data.type,
      default_shipping: data.isDefault,
      default_billing: data.isDefault,
      additional: {
        ...address?.additional,
        otherOptions: data.otherOptions ?? data.type,
        formatted_address: data.address,
        place_id: address?.additional?.place_id ?? '',
      },
    };
    submitAddress?.(updatedAddress);
    // setAddressInformationShow(false);
  }

  const formattedAddress = () => {
    if (!address.additional) {
      return address?.street?.join(' ') + ', ' + address.city;
    } else {
      return address.additional?.formatted_address;
    }
  };
  const setCloseMapInfo = useAppSelector(
    (state) => state.shippingAddressModalReducer.setCloseMapInfo
  );
  return (
    <div
      className={`absolute bottom-0 left-0 right-0 w-full rounded-bl-lg rounded-br-lg ${
        topPosition ? topPosition : ''
      }`}
    >
      <div
        className={clsx(
          'flex h-12 items-center border-t border-light-gray',
          'bg-white/40 px-8 backdrop-blur-sm',
          'rounded-tl-lg rounded-tr-lg lg:rounded-tl-none lg:rounded-tr-none',
          setCloseMapInfo ? 'hidden' : ''
        )}
      >
        <span className='text-custom-sm text-gray'>
          {t('shipping_method_switcher.address_info.title')}
        </span>
      </div>
      <div className='rounded-b-pop-up bg-white px-8 pb-8 pt-6 lg:pb-0'>
        <Formik
          initialValues={{
            type: address.type ?? 'Work',
            address: formattedAddress(),
            isDefault: address.default_shipping ?? false,
            otherOptions: address.additional?.otherOptions ?? address.type,
          }}
          validationSchema={FormSchema}
          onSubmit={(values: IFormData) => {
            handleForm(values);
          }}
        >
          <FormGroup
            isUnknownLocation={isUnknownLocation}
            address={address}
            language={language}
            region={region}
          />
        </Formik>
      </div>
    </div>
  );
}

export function FormGroup({
  address,
  isUnknownLocation,
  language,
  region,
}: {
  address: IAddressData;
  isUnknownLocation: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const pathName = usePathname();
  const isErx = pathName.includes('erx');
  const editId = useAppSelector((state) => state.customerReducer.editId);

  const editAddressCmsContent = useAppSelector(
    (state) => state.addressReducer.editAddressCmsContent
  );
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
  const { setFieldValue, values } = useFormikContext<IFormData>();
  const isUpdatingOrSaving = useAppSelector(
    (state) => state.addressReducer.isUpdatingOrSaving
  );
  const { miniProfile, addressList } = useAppSelector(
    (state) => state.customerReducer
  );

  const formattedAddress = useCallback(() => {
    if (!address.additional) {
      return address?.street?.join(' ') + ', ' + address.city;
    } else {
      return address.additional?.formatted_address;
    }
  }, [address]);

  useEffect(() => {
    setFieldValue('address', formattedAddress());
  }, [setFieldValue, address, formattedAddress]);
  const isEdit = !!editId;
  return (
    <Form>
      <AddressInput cmsIcon={editAddressCmsContent?.icon} isEdit={isEdit} />
      <div className='mt-6 border-t border-light-gray pt-6 lg:border-transparent lg:pt-0'>
        <WorkInput fieldName='type' language={language} region={region} />
        {(values.type === 'Others' ||
          !['Home', 'Work'].includes(values.type)) && (
          <OthersInput
            fieldName='otherOptions'
            language={language}
            region={region}
          />
        )}

        {miniProfile && addressList.length > 0 && !isErx && (
          <ToggleInput
            fieldName='isDefault'
            editAddressCmsContent={
              editAddressCmsContent?.address_type?.label?.[language]
            }
            language={language}
            region={region}
          />
        )}
        <Button
          type='submit'
          disabled={isUpdatingOrSaving || isUnknownLocation}
          className='mt-5 !text-custom-base'
        >
          <div className='relative flex items-center'>
            <span className='flex-1 text-center'>
              {isEdit
                ? t('shipping_method_switcher.address_info.update')
                : t('shipping_method_switcher.address_info.save_address')}
            </span>

            <div className='relative ml-auto'>
              {isUpdatingOrSaving && <LoadingIndicator />}
            </div>
          </div>
        </Button>
      </div>
    </Form>
  );
}
