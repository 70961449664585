import { useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { allowedCountries } from '../utils';

export function usePlacesHook() {
  const [addressSearchValue, setAddressSearchValue] = useState('');
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!,
    debounce: 1000,
    options: {
      input: addressSearchValue,
      componentRestrictions: {
        country: allowedCountries,
      },
    },
  });

  return {
    placePredictions,
    isPlacePredictionsLoading,
    getPlacePredictions,
    placesService,
    addressSearchValue,
    setAddressSearchValue,
  };
}
