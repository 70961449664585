import { useContext } from 'react';
import { AddressMapContext, IMarkerData } from './context';
import { Button } from 'components/buttons/button';
import { LocationDetectIcon } from 'components/icon';
import Target from 'components/icons/target';
import clsx from 'clsx';

export default function AddressLocationPicker() {
  const {
    t,
    setCordinate,
    setMarkers,
    updateWithLatLng,
    map,
    selectedAddress,
  } = useContext(AddressMapContext);

  const pickLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCordinate?.({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          const activeMarkerIndex = 0; // This is here because in our case we are only dealing with one marker
          setMarkers?.((markers) => {
            const mks = [...markers];
            const marker: IMarkerData = {
              ...mks[activeMarkerIndex]!,
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            };
            mks[activeMarkerIndex] = marker;
            return mks;
          });
          map?.setZoom(
            +(process.env.NEXT_PUBLIC_GOOGLE_MAP_DEFAULT_ZOOM ?? 14)
          );
          updateWithLatLng?.(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (err) => err
      );
    }
  };

  return (
    <>
      {!selectedAddress && (
        <Button
          onClick={pickLocation}
          className='mb-6 hidden lg:mt-[30px] lg:block'
        >
          <div className='flex items-center justify-between px-2'>
            <LocationDetectIcon />
            <span className='text-custom-base'>
              {t?.('shipping_method_switcher.address_modal.location')}
            </span>
            <div></div>
          </div>
        </Button>
      )}
      <button
        onClick={pickLocation}
        className={clsx(
          'absolute bottom-6 right-4 z-50 flex size-12 items-center justify-center rounded-full border-none bg-white shadow-2xl outline-none transition-all duration-300 lg:hidden',
          `${selectedAddress && 'bottom-[460px]'}`
        )}
      >
        <Target />
      </button>
    </>
  );
}
