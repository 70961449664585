import { createSlice } from '@reduxjs/toolkit';
import { StoreStock } from 'types/store';

type CheckoutState = {
  activePaymentMethod: string;
  selectedStore: StoreStock | undefined;
  selectedStoreFromMap: boolean;
  storePickupStage: 'store' | 'payment';
  locationServiceDenied: boolean;
  slot?: {
    timeSlotId: string;
    timeSlot: string;
  };
};

const initialState: CheckoutState = {
  activePaymentMethod: '',
  selectedStore: undefined,
  selectedStoreFromMap: false,
  storePickupStage: 'store',
  locationServiceDenied: false,
};

export const checkout = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setActivePaymentMethod: (state, action: { payload: string }) => {
      state.activePaymentMethod = action.payload;
    },
    setSelectedStore: (state, action: { payload: StoreStock | undefined }) => {
      state.selectedStore = action.payload;
    },
    setSelectedStoreFromMap: (state, action: { payload: boolean }) => {
      state.selectedStoreFromMap = action.payload;
    },
    setStorePickupStage: (state, action: { payload: 'store' | 'payment' }) => {
      state.storePickupStage = action.payload;
    },
    setLocationServiceDenied: (state, action: { payload: boolean }) => {
      state.locationServiceDenied = action.payload;
    },
    setSlot: (
      state,
      action: { payload: { timeSlotId: string; timeSlot: string } }
    ) => {
      state.slot = action.payload;
    },
  },
});

export const {
  setActivePaymentMethod,
  setSelectedStore,
  setSelectedStoreFromMap,
  setStorePickupStage,
  setLocationServiceDenied,
  setSlot,
} = checkout.actions;
export default checkout.reducer;
