/* eslint-disable no-unused-vars */
import { Dispatch, SetStateAction, createContext } from 'react';
import { DEFAULT_GEO } from '../utils';
import { IAddressData, INearByStore } from 'types/shipping-method-switcher';
import { MarkerProps } from '@react-google-maps/api';
import type { StoreStock } from 'types/store';

export interface IMapCordinate {
  lat: number;
  lng: number;
}

export interface IMarkerData {
  position: IMapCordinate;
  draggable: boolean;
  store?: INearByStore | StoreStock;
  icon?: {
    url?: MarkerProps['icon'];
    scaledSize?: google.maps.Size | null | undefined;
  };
  markerIcon?: string;
}

export type containerStyle = React.CSSProperties;

export interface IMapContext {
  setZoom?: (level: number) => void;
  setCenter?: Dispatch<SetStateAction<IMapCordinate>>;
  centerMarker?: IMarkerData;
  setCenterMarker?: Dispatch<SetStateAction<IMarkerData | undefined>>;
  markers: IMarkerData[];
  setMarkers?: Dispatch<SetStateAction<IMarkerData[]>>;
  setMapContainer?: (style: containerStyle) => void;
  containerStyle: containerStyle;
  map: google.maps.Map | null;
  setMap?: Dispatch<SetStateAction<google.maps.Map | null>>;
  cordinate: IMapCordinate;
  setCordinate?: Dispatch<SetStateAction<IMapCordinate>>;
  showAddressOptions: boolean;
  setShowAddressOptions?: Dispatch<SetStateAction<boolean>>;
  selectedAddress: IAddressData | null;
  setSelectedAddress?: Dispatch<SetStateAction<IAddressData | null>>;
  submitAddress?: (data: IAddressData) => void;
  updateWithLatLng?: (lat: number, lng: number, map?: google.maps.Map) => void;
  selectedStore?: INearByStore | undefined;
  setSelectedStore?:
    | ((selectedStore: INearByStore | undefined) => void)
    | ((selectedStore: StoreStock | undefined) => void);
  activeMarker?: google.maps.LatLng | null;
  setActiveMarker?: (activeMarker: google.maps.LatLng | null) => void;
  isUnknownLocation: boolean;
  setIsUnknownLocation?: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  t?: Function; // eslint-disable-next-line @typescript-eslint/ban-types
  ts?: Function;
}

const initialState: IMapContext = {
  markers: [],
  map: null,
  showAddressOptions: false,
  containerStyle: {
    width: '100%',
    height: '100%',
  },
  cordinate: DEFAULT_GEO,
  selectedAddress: null,
  isUnknownLocation: false,
};

export const AddressMapContext = createContext<IMapContext>(initialState);
