import { ChangeEvent, useContext } from 'react';
import { AddressMapContext, IMarkerData } from './context';
import {
  CircularCancel,
  SearchIcon,
  SecondaryLocationIcon,
} from 'components/icon';
import clsx from 'clsx';
import { usePlacesHook } from './hooks';
import { SearchAddressType } from 'types/shipping-method-switcher';
import { extractAddressDetails } from './utils';
import { toAddressFormat } from './utils/address.util';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingIndicator from './map-loader';
import { setMapOptions } from 'redux/features/address.reducer';
import { setCheckoutStores } from 'redux/features/store-availability';
import Image from 'next/image';
import type { CartType } from 'types/my-cart';
import { cartStoreAvailability } from './utils/store-pickup';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { usePathname } from 'next/navigation';

export default function AddressSearchInput({
  language,
  region,
  cart,
  isStoreAvailability,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
  cart?: CartType;
  isStoreAvailability?: boolean;
}) {
  const {
    placePredictions,
    isPlacePredictionsLoading,
    getPlacePredictions,
    placesService,
    addressSearchValue,
    setAddressSearchValue,
  } = usePlacesHook();
  const {
    ts,
    setShowAddressOptions,
    setSelectedAddress,
    setCordinate,
    setMarkers,
    setCenterMarker,
    setIsUnknownLocation,
  } = useContext(AddressMapContext);
  const appDispatch = useAppDispatch();
  const miniProfile = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );
  const editAddressCmsContent = useAppSelector(
    (state) => state.addressReducer.editAddressCmsContent
  );
  const pathname = usePathname();
  const makeBold = (item: string, keyword: string) => {
    const re = new RegExp(keyword, 'g');
    return item.replace(
      re,
      '<span class="font-semibold">' + keyword + '</span>'
    );
  };
  function handleAddressSearch(e: ChangeEvent<HTMLInputElement>) {
    setAddressSearchValue(e.target.value);
    getPlacePredictions({ input: e.target.value });
    setShowAddressOptions?.(false);
  }

  function handleAddressSection(placeId: string) {
    placesService?.getDetails({ placeId }, (place) => {
      setIsUnknownLocation?.(false);
      if (isStoreAvailability) {
        setCenterMarker?.({
          position: {
            lat: place?.geometry?.location?.lat() ?? 0,
            lng: place?.geometry?.location?.lng() ?? 0,
          },
          draggable: false,
        });

        cartStoreAvailability({
          lat: place?.geometry?.location?.lat() ?? 0,
          lng: place?.geometry?.location?.lng() ?? 0,
          cart: cart as CartType,
          setCordinate,
          setMarkers,
          language,
          region,
        }).then((data) => {
          if (data && data.success && data.stores) {
            appDispatch(setCheckoutStores(data.stores));
          }
        });
      } else {
        appDispatch(setMapOptions(true));
        const addressDetail = extractAddressDetails(
          place?.address_components ?? []
        );
        const data: SearchAddressType = {
          place_id: placeId,
          lat: place?.geometry?.location?.lat() ?? 0,
          lng: place?.geometry?.location?.lng() ?? 0,
          type: 'Home',
          isDefault: false,
          url: place?.url,
          formatted_address: place?.formatted_address ?? '',
          location: addressDetail,
          firstName: miniProfile?.firstname,
          lastName: miniProfile?.lastname,
        };
        const formattedAddress = toAddressFormat(data);
        setSelectedAddress?.(formattedAddress);
        setCordinate?.({
          lat: place?.geometry?.location?.lat() ?? 0,
          lng: place?.geometry?.location?.lng() ?? 0,
        });
        const activeMarkerIndex = 0;
        setMarkers?.((markers) => {
          const mks = [...markers];
          const marker: IMarkerData = {
            ...mks[activeMarkerIndex]!,
            position: {
              lat: place?.geometry?.location?.lat() ?? 0,
              lng: place?.geometry?.location?.lng() ?? 0,
            },
          };
          mks[activeMarkerIndex] = marker;
          return mks;
        });
      }

      setAddressSearchValue('');
    });
  }

  return (
    <div className='relative mt-6'>
      <div className='relative h-11'>
        {editAddressCmsContent?.map_data?.search_address_fields?.icon ? (
          <Image
            src={editAddressCmsContent.map_data.search_address_fields.icon}
            alt='icon'
            width={20}
            height={20}
            className='absolute top-[50%] h-5 w-5 -translate-y-1/2 translate-x-[-50%] ltr:left-6 rtl:right-2'
          />
        ) : (
          <SearchIcon
            className={clsx(
              'has-color absolute top-[50%] -translate-y-1/2',
              'translate-x-[-50%] fill-current text-light-gray ltr:left-6 rtl:right-2'
            )}
          />
        )}
        <input
          type='text'
          placeholder={
            editAddressCmsContent?.map_data?.search_address_fields
              ?.placeholder?.[language] || pathname?.includes('/checkout')
              ? ts?.(
                  'shipping_method_switcher.address_modal.search_store_address'
                )
              : ts?.(
                  'shipping_method_switcher.address_modal.search_placeholder'
                )
          }
          value={addressSearchValue}
          onChange={(e) => handleAddressSearch(e)}
          className={clsx(
            'h-full w-full rounded-lg border border-light-gray',
            'text-custom-base placeholder:text-gray ltr:pl-[44px] rtl:pr-[44px]',
            'focus:border-2 focus:border-blue focus:ring-0',
            'js-search-input'
          )}
        />
        {isPlacePredictionsLoading && <LoadingIndicator />}
        {addressSearchValue.length > 0 && !isPlacePredictionsLoading && (
          <CircularCancel
            onClick={() => setAddressSearchValue('')}
            className='absolute top-[50%] z-[1] cursor-pointer ltr:right-3 rtl:left-3'
            style={{ transform: 'translate(-50%,-50%)' }}
          />
        )}
      </div>

      {placePredictions.length > 0 && addressSearchValue && (
        <div className='absolute top-12 z-30 w-full rounded-lg bg-white'>
          <ul className='space-y-6 overflow-y-auto py-4'>
            {placePredictions.map(
              (
                {
                  place_id,
                  structured_formatting: { secondary_text, main_text },
                },
                index
              ) => (
                <li
                  key={`address-list-${index}`}
                  onClick={() => handleAddressSection(place_id)}
                  className='flex cursor-pointer items-center space-x-2 hover:bg-light-gray-100 rtl:space-x-reverse'
                >
                  <SecondaryLocationIcon
                    className={
                      'h-12 w-12 rounded-full border-2 border-blue-light bg-blue-light p-2'
                    }
                  />
                  <div className='flex flex-col'>
                    <p
                      className='text-custom-sm uppercase text-gray-dark'
                      dangerouslySetInnerHTML={{
                        __html: makeBold(main_text, addressSearchValue),
                      }}
                    />

                    <span className='text-custom-xs text-gray'>
                      {secondary_text}
                    </span>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
