import clsx from 'clsx';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { SwitchType } from 'types/switch';

const Switch = forwardRef<HTMLInputElement, SwitchType>((props, ref) => {
  const {
    name,
    inputClassName,
    parentClassName,
    checked,
    onChange,
    buttonBackgroundColor,
    buttonColor,
    ...rest
  } = props;

  if (
    typeof document !== 'undefined' &&
    (buttonBackgroundColor || buttonColor)
  ) {
    const style = document.createElement('style');
    style.id = `${name}-switch-style`;
    style.innerHTML = `
      #${name}:checked {
        background-color: ${props.buttonBackgroundColor};
      }
        
      #${name} + label {
        background-color: ${props.buttonColor};
      }
    `;
    if (!document.getElementById(`${name}-switch-style`)) {
      document.head.appendChild(style);
    }
  }

  return (
    <div
      className={twMerge(
        'relative inline-block h-8 w-[52px] cursor-pointer rounded-full',
        parentClassName
      )}
    >
      <input
        ref={ref}
        {...rest}
        id={name}
        name={name}
        type='checkbox'
        className={twMerge(
          clsx(
            'peer absolute h-full w-full bg-[#A3A2A2]',
            'cursor-pointer appearance-none rounded-full transition-colors duration-300',
            'checked:bg-blue peer-checked:border-blue peer-checked:before:bg-blue',
            'disabled:cursor-not-allowed disabled:bg-gray-200'
          ),
          inputClassName
        )}
        checked={checked}
        onChange={onChange}
      />
      <label
        htmlFor={name}
        className="before:content[''] before:bg-blue-gray-500 absolute left-[0.175rem] top-2/4 h-7 w-7 -translate-y-2/4 cursor-pointer rounded-full border border-white bg-white shadow-md transition-all duration-300 before:absolute before:left-2/4 before:top-2/4 before:block before:h-10 before:w-10 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity peer-checked:-left-1.5 peer-checked:translate-x-full peer-checked:border-white peer-checked:before:bg-blue"
      >
        {/* <div
          className='left-2/4 top-2/4 inline-block -translate-x-2/4 -translate-y-2/4 rounded-full p-5'
          data-ripple-dark='true'
        ></div> */}
      </label>
    </div>
  );
});
Switch.displayName = 'Switch';
export { Switch };
